:root {
  --primary-sailor-color: #ff2f59;
  --primary-ahoy-color: #da324d;
  --primary-base-background-color: #ffffff;
  --secondary-fire-color: #ff781f;
  --secondary-saphire-color: #4d88ff;
  --secondary-amethyst-color: #ca61ff;
  --background-color: #f7f7f7;
  --darker-background-color: #dfe0e6;
  --contents-box-background: #f7f7f7;
  --border-color: #dedede;
  --overcast-color: #acacac;
  --tuscany-color: #fcd12a;
  --internal-only-color: #e3ecff;
  --search-highlight-color: #feedaa;
  --text-color: #222;
  --intense-text-color: #000;
  --emerald-d20-color: #40a060;
  --gutter: 2rem;
  --nav-width: 20vw;
  --header-height: 5rem;
}

/* main layout */

.margin-right {
  margin-right: 1rem;
}
.margin-left {
  margin-left: 1rem;
}

body {
  background-color: var(--primary-base-background-color);
  padding: 0;
  margin: 0;
  transition: all ease-in-out 0.2s;
}

body.initial-load {
  transition: none;
}

main {
  display: grid;
  grid-template-columns: var(--nav-width) auto;
  grid-column-gap: var(--gutter);
}
main.loading {
  background-image: url(./images/loader.gif);
  background-position: top var(--gutter) right var(--gutter);
  background-repeat: no-repeat;
  background-size: 2rem;
}

#okta {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  z-index: 1;
}
#okta > * {
  top: 40%;
  transform: translateY(-50%);
}
.login {
  bottom: 0.5em;
  left: var(--gutter);
  top: auto;
  font-size: 0.9rem;
  position: absolute;
}

/* header */

header {
  align-items: center;
  background-image: url(./images/background.png);
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  min-height: var(--header-height);
  justify-content: space-between;
  padding: 0 var(--gutter);
  position: sticky;
  color: var(--primary-base-background-color);
  z-index: 998;
  top: 0;
}

header > div:first-child {
  align-items: center;
  display: flex;
  flex-shrink: 0;
}

header > div:last-child {
  text-align: right;
}

.tagline {
  color: var(--primary-sailor-color);
  font-size: 1.1rem;
  margin-left: 0.5rem;
  display: inline-block;
}

body.internal .tagline::after {
  color: var(--secondary-fire-color);
  content: " ⚠️";
}

body:not(.internal) .internal-only {
  display: none;
}

.internal-only {
  background-color: var(--internal-only-color);
  box-shadow: 0 0 0.2rem 0.5rem var(--internal-only-color);
  position: relative;
  border-radius: 1px;
}
.internal-only::after {
  color: var(--secondary-fire-color);
  content: " ⚠️ Internal Only";
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  right: 0;
  top: 0;
}
h1.internal-only a[data-status] {
  display: none;
}
h1.internal-only {
  box-shadow: 0 0 0 1rem var(--internal-only-color);
}

header .right a {
  color: inherit;
  font-size: 0.9rem;
  margin-left: 1.5em;
  font-weight: bolder;
  cursor: pointer;
}

header .right a:hover {
  color: var(--background-color);
  text-decoration: none;
}

/* document layout */

article {
  box-sizing: border-box;
  display: block;
  min-width: 50vw;
  padding: var(--gutter) var(--gutter) var(--gutter) 0;
  width: calc(100vw - var(--gutter) - var(--nav-width));
}

article > h1:first-child {
  margin-top: 0.5rem;
}

@media only screen and (min-width: 1400px) {
  article {
    width: calc(100vw - var(--gutter) - 2 * var(--nav-width));
  }
}

@media only screen and (max-width: 900px) {
  main {
    grid-template-columns: 0 auto;
    grid-column-gap: 0;
  }
  nav {
    display: none;
  }
  article {
    width: 100vw;
    padding: var(--gutter);
  }
  h2,
  h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
}

nav {
  background-color: var(--background-color);
  box-sizing: border-box;
  grid-column: 1 / span 1;
  height: calc(100vh - var(--header-height));
  overflow-y: auto;
  padding: var(--gutter);
  position: sticky;
  top: var(--header-height);
}

article {
  grid-column: 2 / span 1;
  line-height: 1.6rem;
}

footer {
  margin: 0 auto;
  padding: 2rem 0;
  display: flex;
  justify-content: space-around;
  border-top: solid var(--border-color) 1px;
}

/* typography */

body {
  color: var(--text-color);
  font-family: "Lato", sans-serif;
  font-size: 16px;
}

h1 {
  position: relative;
  margin-top: 4rem;
}

h2 {
  line-height: 2rem;
  margin-top: 3.5rem;
}

h3 {
  margin: 1.5rem 0;
}

h4 {
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

h5 {
  color: var(--overcast-color);
  font-size: 0.9rem;
  font-weight: normal;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  text-transform: uppercase;
}

p,
blockquote {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

li p {
  margin: 0;
}

hr {
  border: 0;
  border-bottom: solid var(--border-color) 1px;
  margin-top: 2.2rem;
}

code {
  background: var(--background-color);
  border-radius: 3px;
  padding: 0.3em 0.6em;
  font-size: 0.9em;
}

.video-wrapper {
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  position: relative;
  margin: 2rem 0;
}

/* give videos a max width of 600px */
@media only screen and (min-width: 648px) {
  .video-wrapper {
    height: 340px;
    padding-bottom: 0;
    width: 600px;
  }
}

.video-wrapper iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.tags code {
  box-sizing: border-box;
  display: inline-block;
  font-family: inherit;
  font-size: 0.8em;
  margin: 0 0.8em 0.8em 0;
  padding: 0 0.6em;
}
.tags {
  margin-top: -0.5em;
}

pre {
  /*background: var(--background-color) !important;*/
  border-radius: 3px !important;
  border: none !important;
  box-shadow: none !important;
  max-height: 30rem;
  overflow-y: auto;
  padding: 0 !important;
}

pre code {
  display: block;
  padding: var(--gutter);
  white-space: pre-wrap !important;
}

.code-wrapper {
  position: relative;
}
.code-wrapper [data-clipboard-text] {
  background: var(--darker-background-color);
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.7rem;
  line-height: 1em;
  opacity: 0;
  padding: 0.3em 0.4em;
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  transition: all 0.2s;
}
.code-wrapper:hover [data-clipboard-text] {
  opacity: 0.7;
}
.code-wrapper:hover [data-clipboard-text]:hover {
  opacity: 1;
}

button {
  background: var(--primary-base-background-color);
  background: transparent;
  border-radius: 1.4em;
  border: solid var(--primary-sailor-color) 2px;
  color: var(--primary-sailor-color);
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 0.8rem;
  line-height: inherit;
  padding: 0.2em 1em;
  transition: all 0.2s;
}
button.utility {
  border: solid var(--secondary-amethyst-color) 2px;
  color: var(--secondary-amethyst-color);
}
button.info {
  border: solid var(--secondary-saphire-color) 2px;
  color: var(--secondary-saphire-color);
}
button.warn {
  border: solid var(--primary-ahoy-color) 2px;
  color: var(--primary-ahoy-color);
}
button.grayed {
  border: solid var(--gray-color) 2px;
  border: solid 2px;
  color: var(--gray-color);
}
button.tertiary {
  border: none;
  background-color: transparent;
}
div.warn {
  border-radius: 5px;
  padding: 0.5rem 1rem;
  border: var(--red-color) solid 2px;
}

textarea {
  box-sizing: border-box;
  font-family: monospace;
  font-size: 0.8rem;
  min-height: 8rem;
  padding: 1rem;
  width: 100%;
}

input[type="text"] {
  background-color: var(--primary-base-background-color);
  box-sizing: border-box;
  font-family: inherit;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 100%;
}

input[type="password"] {
  background-color: var(--primary-base-background-color);
  box-sizing: border-box;
  font-family: inherit;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 100%;
}

blockquote {
  background: var(--background-color);
  border-left: solid var(--primary-sailor-color) 4px;
  margin: 0.5rem 0 0 0;
  padding: 1rem 1rem 1rem 1.5rem;
}

blockquote p {
  margin: 0;
}

.method {
  border: solid var(--overcast-color) 2px;
  color: var(--overcast-color);
  display: inline-block;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0 0.5ch;
  height: 1.4em;
  line-height: 1.4em;
}

h2 .method,
h3 .method,
h4 .method {
  margin-right: 0.2rem;
}

.method.get {
  border-color: var(--emerald-d20-color);
  color: var(--emerald-d20-color);
}
.method.post {
  border-color: var(--secondary-fire-color);
  color: var(--secondary-fire-color);
}
.method.put {
  border-color: var(--secondary-saphire-color);
  color: var(--secondary-saphire-color);
}
.method.patch {
  border-color: var(--secondary-amethyst-color);
  color: var(--secondary-amethyst-color);
}
.method.delete {
  border-color: var(--primary-sailor-color);
  color: var(--primary-sailor-color);
}

/* anchors */

h1 a.anchor {
  color: var(--primary-sailor-color);
}

h2,
h3 {
  position: relative;
}

h2 a.anchor::before,
h3 a.anchor::before {
  color: var(--border-color);
  content: "#";
  cursor: pointer;
  display: none;
  font-size: 1em;
  font-weight: normal;
  left: -1rem;
  position: absolute;
  vertical-align: baseline;
  width: 1rem;
}

h2:hover a.anchor::before,
h3:hover a.anchor::before {
  display: inline-block;
}

/* tables */

.table-wrapper {
  overflow-x: scroll;
}

table {
  border-collapse: collapse;
  font-size: 0.9rem;
  overflow-x: scroll;
  width: 100%;
}

table ul {
  padding-left: 1.1em;
}

th,
td {
  text-align: left;
  vertical-align: top;
  border-bottom: solid var(--border-color) 1px;
  padding: 0.25rem 0.5rem;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

.schema-table .type {
  font-size: 0.9em;
}
.schema-table td:last-child {
  width: 100%;
}
.schema-table td:last-child p:first-child {
  margin: 0;
}
.schema-table td:first-child code {
  background: none;
  color: var(--intense-text-color);
  font-size: 1em;
  padding: 0;
  margin: 0;
}
.schema-table td:first-child code em {
  color: var(--overcast-color);
  font-weight: normal;
}

.schema-table .toggle-handle {
  border-radius: 0;
  border: 0;
  color: var(--overcast-color);
  display: inline-block;
  margin: 0;
  padding: 0;
  text-transform: upper-case;
  user-select: none;
}
.schema-table .toggle-handle.active {
  background: none;
  color: var(--overcast-color);
}
.schema-table .toggle-handle::after {
  display: none;
}

/* nav */

nav h2,
nav h3 {
  box-sizing: border-box;
  font-weight: normal;
  line-height: 2rem;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

nav h2 {
  font-size: 1.1rem;
  margin-top: 1rem;
}
/*nav h2:first-child,*/
nav h2 + h2 {
  margin-top: 0;
}

nav h3 {
  font-size: 0.9rem;
  padding-left: 5px;
}

nav a {
  color: var(--text-color);
}

nav .method {
  position: relative;
  top: -1px;
  margin: 0 3px 0 -5px;
}

nav .internal-only,
tr.internal-only {
  box-shadow: none;
}
nav .internal-only::after {
  content: "⚠️";
  font-size: 1em;
  right: 0.3em;
}
nav .internal-only + .internal-only::after,
tr.internal-only::after {
  content: "";
}

nav label {
  cursor: pointer;
  display: block;
  font-size: 0.9rem;
  line-height: 1.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

nav input[type="checkbox"] {
  cursor: pointer;
  margin: 0 0.5em;
}

.filtered-out {
  display: none;
}

/* status page */

a[data-status] {
  color: var(--overcast-color);
  font-size: 0.9rem;
  font-weight: normal;
  line-height: 1em;
  padding-left: calc(10px + 0.5em);
  position: absolute;
}

h1 a[data-status] {
  right: 0;
  top: 0.5rem;
}

a[data-status]:hover {
  text-decoration: none;
}

a[data-status]::before {
  background-color: var(--overcast-color);
  border-radius: 50%;
  content: "";
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
}

a[data-status]::after {
  content: "Unknown Status";
}

a[data-status="operational"] {
  color: var(--primary-sailor-color);
}
a[data-status="operational"]::before {
  background-color: var(--primary-sailor-color);
}
a[data-status="operational"]::after {
  content: "Operational";
}

a[data-status="degraded_performance"] {
  color: var(--tuscany-color);
}
a[data-status="degraded_performance"]::before {
  background-color: var(--tuscany-color);
}
a[data-status="degraded_performance"]::after {
  content: "Degraded";
}

a[data-status="partial_outage"] {
  color: var(--secondary-fire-color);
}
a[data-status="partial_outage"]::before {
  background-color: var(--secondary-fire-color);
}
a[data-status="partial_outage"]::after {
  content: "Partial Outage";
}

a[data-status="major_outage"] {
  color: var(--primary-ahoy-color);
}
a[data-status="major_outage"]::before {
  background-color: var(--primary-ahoy-color);
}
a[data-status="major_outage"]::after {
  content: "Major Outage";
}

/* links */

a {
  color: var(--secondary-saphire-color);
  text-decoration: none;
  transition: all 0.2s;
}

a:hover {
  text-decoration: underline;
}

a[target="_blank"]::after {
  content: " \2197";
}

/* toggles */

.toggle-handle {
  display: inline-block;
}
.toggle-handle + * {
  display: none;
}
.toggle-handle.active + * {
  display: block;
}
.toggle-handle::before {
  content: "Show ";
}
.toggle-handle.active::before {
  content: "Hide ";
}
.toggle-handle::after {
  content: "+";
  font-size: 22px;
  left: 9px;
  position: absolute;
  top: 2px;
}
.toggle-handle.active::after {
  transform: rotate(45deg);
}
.toggle-handle {
  border: solid var(--secondary-saphire-color) 2px;
  border-radius: 1.4em;
  color: var(--secondary-saphire-color);
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  margin: 1rem 0;
  padding: 0.2em 1em 0.2em 2em;
  position: relative;
  transition: all 0.2s;
}
.toggle-handle.active {
  background: var(--secondary-saphire-color);
  color: var(--primary-base-background-color);
  filter: none;
}

/* tabs */

.tab-handle-wrapper {
  margin: 1rem 0;
  position: relative;
}
.tab-handle,
.static-tab-handle {
  border: solid var(--secondary-amethyst-color) 2px;
  border-radius: 1.4em;
  color: var(--secondary-amethyst-color);
  cursor: pointer;
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  padding: 0.2em 1em;
  transition: all 0.2s;
}
.tab-handle.active,
.static-tab-handle.active {
  background: var(--secondary-amethyst-color);
  color: var(--primary-base-background-color);
  filter: none;
  pointer-events: none;
}
.tab-handle:not(.active):hover,
.static-tab-handle:not(.active):hover {
  filter: brightness(85%);
}
.tab-content:not(.active) {
  display: none;
}

/* from-raml customizations */

.from-raml table td:first-child,
.from-raml table th:first-child {
  text-align: right;
}

.from-raml table td:first-child em {
  color: var(--overcast-color);
  display: block;
}

.from-raml table td:last-child em:first-child {
  color: var(--overcast-color);
  display: block;
}

.from-raml.uri-prefix {
  color: var(--overcast-color);
}
nav .from-raml.uri-prefix {
  display: none;
}

.from-raml table .code {
  background-color: var(--background-color);
  border-radius: 3px;
  display: block;
  font-family: monospace;
  font-size: 0.9em;
  line-height: 1.5;
  padding: 1em;
  position: relative;
}

.from-raml table .tab {
  margin-left: 1em;
}

/* header search */

#header-search {
  background-color: var(--background-color);
  border-radius: 4px;
  border: 0;
  box-shadow: none;
  font-family: inherit;
  outline: none;
  padding: 0.8rem 1rem 0.8rem 2.8rem;
  width: 100%;
}

#header-search-label {
  border-radius: 4px;
  display: inline-block;
  position: relative;
  z-index: 999;
}

#header-search-label > svg {
  margin-right: 0.5ch;
  font-size: 0.9em;
  transition: all ease 0.2s;
}

#header-search-label:not(:focus-within)::after {
  content: "Search";
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bolder;
}
#header-search-label:not(:focus-within)::before {
  content: "";
}
#header-search-label:not(:focus-within):hover {
  color: var(--background-color);
}

#header-search-label:not(:focus-within) #header-search {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#header-search-label::before {
  content: "🔍";
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
}

#header-search:focus {
  box-shadow: 0px 4px 12px rgba(100, 100, 100, 0.3);
}

#header-search-label:focus-within {
  transform: translateX(-50%);
  top: calc(var(--header-height) / 2);
  box-shadow: 0 0 0 10000px rgb(205, 205, 210, 0.6);
  position: fixed;
  min-width: 500px;
  max-width: 900px;
  width: calc(57vw - var(--gutter));
  left: 50%;
}
#header-search-label:focus-within > svg {
  display: none;
}

@media only screen and (max-width: 840px) {
  #header-search-label {
    display: none;
  }
}

.autocomplete-suggestions {
  background-color: var(--primary-base-background-color) !important;
  border-color: var(--border-color) !important;
  border-radius: 4px;
  border: 0 !important;
  box-shadow: 0px 4px 12px rgba(100, 100, 100, 0.3);
  box-sizing: border-box;
  cursor: pointer !important;
  max-height: 80vh !important;
  position: fixed !important;
  top: 88px !important;
  transform: translateY(3px);
  z-index: 999;
}
.autocomplete-suggestion {
  box-shadow: 0;
  border: 0;
}

.autocomplete-suggestion.header {
  box-sizing: border-box;
  color: var(--text-color) !important;
  overflow: auto;
  padding: 0.8rem 1rem;
  position: relative;
  text-overflow: initial;
  white-space: normal;
}

.autocomplete-suggestion.header .search-type {
  background-color: var(--background-color);
  border-radius: 3px;
  color: var(--text-color);
  font-size: 0.8em;
  line-height: 1em;
  padding: 0.3em 0.5em;
  position: absolute;
  right: 1rem;
}

.autocomplete-suggestion.header .search-description {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: var(--overcast-color);
  display: -webkit-box;
  font-size: 0.9rem;
  line-clamp: 3;
  line-height: 1.6em;
  margin-top: 0.6rem;
  max-height: 4.8em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-suggestion.header[data-route^="/apis/"]::before {
  content: "📖 ";
}
.autocomplete-suggestion.header[data-route^="/data-shares/"]::before {
  content: "☁️ ";
}
.autocomplete-suggestion.header[data-route^="/data-sets/"]::before {
  content: "📊 ";
}

.autocomplete-suggestion.header.selected {
  background: var(--background-color);
}

.autocomplete-suggestion.header b {
  color: var(--secondary-saphire-color);
}

/* sidebar search */

nav input[type="search"] {
  background-color: var(--primary-base-background-color);
  border-radius: 4px;
  border: solid var(--border-color) 1px;
  color: inherit;
  font-family: inherit;
  font-size: 0.9rem;
  outline: none;
  padding: 0.8em 1em;
  width: 100%;
}

input[type="search"]:focus {
  border-color: var(--secondary-saphire-color);
  box-shadow: 0px 0px 5px 0px var(--secondary-saphire-color);
}

.search-result {
  background-color: var(--search-highlight-color);
  border-radius: 0;
  box-shadow: 0 0 0 0.5rem var(--search-highlight-color);
  position: relative;
  z-index: 0;
}

.search-result-count {
  color: var(--overcast-color);
  font-size: 0.8em;
  pointer-events: none;
  position: absolute;
  right: calc(var(--gutter) + 0.6em);
  top: calc(var(--gutter) + 13px);
}

/* maintainers */

.maintainers {
  background: none;
  box-shadow: none;
  display: inline-block;
  font-weight: normal;
  margin-bottom: 1em;
}
.maintainers::after {
  content: "";
}
.maintainers::before {
  content: "⚠️ Maintainers: ";
}
.maintainers code {
  margin-left: 0.5em;
  margin-right: 0;
}
.maintainers a {
  text-decoration: none;
}
.maintainers a::before {
  content: "🛠️ ";
}

h1 .maintainers {
  font-size: 1rem;
  margin: 1em 0 0 0;
}

/* pop-grids */

.pop-grid {
  display: grid;
  grid-template-columns: calc(50% - var(--gutter) / 2) calc(
      50% - var(--gutter) / 2
    );
  grid-gap: calc(var(--gutter) * 0.75);
  margin-top: calc(var(--gutter) * 0.75);
}

@media only screen and (max-width: 700px) {
  .pop-grid {
    grid-template-columns: 100%;
  }
}

.pop-grid-item {
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(100, 100, 100, 0.1);
  border: solid var(--border-color) 1px;
  cursor: pointer;
  padding: 1.5rem;
  transition: all ease-in-out 0.2s;
  color: inherit;
}
.pop-grid-item:hover {
  border-color: var(--primary-sailor-color);
}
.pop-grid-item::after {
  content: "";
}
.pop-grid-item p {
  margin: 0;
  padding: 0;
  pointer-events: none;
}

.pop-grid-item a {
  display: block;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  color: inherit;
}
.pop-grid-item a:hover {
  text-decoration: none;
}

.pop-grid.small .pop-grid-item {
  padding: 1rem 1.5rem;
  text-decoration: none;
  position: relative;
}
.pop-grid.small .pop-grid-item::after {
  content: "→";
  font-size: 1.2em;
  opacity: 0;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%) translateX(-6px);
  transition: all ease-in-out 0.1s;
  color: var(--primary-sailor-color);
}
.pop-grid.small .pop-grid-item:hover::after {
  opacity: 1;
  transform: translateY(-50%) translateX(0);
}

@media print {
  main {
    grid-template-columns: 0 auto;
    grid-column-gap: 0;
  }
  article {
    width: 100vw;
    padding: 0;
  }
  nav,
  header {
    display: none;
  }

  .tab-handle-wrapper {
    display: none;
  }
  [data-tab] {
    display: block !important;
  }
  [data-tab]::before {
    content: attr(data-tab);
    display: block;
    font-weight: bold;
  }

  pre code {
    padding: 0.5rem;
    border: solid var(--border-color) 1px;
  }
  pre {
    max-height: none;
    font-size: 0.8em;
  }

  h2:not(:first-of-type) {
    break-before: page;
  }

  a {
    color: var(--text-color);
  }
  a:not(.anchor)::after {
    content: " (" attr(href) ")";
  }
  a[data-status] {
    display: none;
  }
}

/* form */

.contact-form-HsWrapper.contact-form-HsWrapper {
  background-color: var(--background-color);
  color: var(--text-color);
}
.contact-form-Select.contact-form-Select .css-i0syzg-menu {
  background-color: var(--background-color);
  color: var(--text-color);
}

.contact-form-Flex button {
  margin: px;
}

/* dark mode */

body.dark-mode {
  --primary-base-background-color: rgb(41, 45, 62);
  --background-color: rgb(33, 36, 50);
  --text-color: rgb(168, 193, 202);
  --intense-text-color: #eee;
  --border-color: #454545;
  --secondary-saphire-color: #83acff;
  --secondary-amethyst-color: #a24ecc;
  --secondary-fire-color: #ff781f;
  --darker-background-color: var(--background-color);
  --search-highlight-color: #3e6dcc;
  --border-color-alt: #4d505d;
  --overcast-color: #a8c1caa3;
  --contents-box-background: #111d36;
  --internal-only-color: #1b305c;
}
body.dark-mode nav {
  background-color: var(--primary-base-background-color);
}
body.dark-mode header {
  border-bottom: 0;
  background-image: none;
  background: linear-gradient(
    90deg,
    rgba(24, 24, 24, 1) 0%,
    rgba(31, 20, 17, 1) 25%,
    rgba(52, 24, 37, 1) 50%,
    rgba(34, 28, 64, 1) 75%,
    rgba(21, 43, 64, 1) 100%
  );
  color: var(--text-color);
}
body.dark-mode header .right a:hover,
body.dark-mode #header-search-label:not(:focus-within):hover {
  color: var(--intense-text-color);
}
body.dark-mode .pop-grid-item {
  background-color: var(--background-color);
  box-shadow: none !important;
  border-color: transparent !important;
}
body.dark-mode .pop-grid.small .pop-grid-item::after {
  color: inherit;
}
body.dark-mode input[type="search"],
body.dark-mode input[type="text"],
body.dark-mode input[type="password"],
body.dark-mode textarea {
  background: var(--background-color) !important;
  color: var(--text-color);
}
body.dark-mode input[type="search"]::placeholder,
body.dark-mode input[type="text"]::placeholder,
body.dark-mode input[type="password"]::placeholder,
body.dark-mode textarea::placeholder {
  color: var(--text-color);
}
body.dark-mode th,
body.dark-mode td,
body.dark-mode hr {
  border-bottom: solid var(--border-color-alt) 1px;
}
body.dark-mode .schema-table .schema-table {
  border: solid var(--border-color-alt) 1px;
}
body.dark-mode #logo {
  background-position-y: 1px;
}
body.dark-mode h2 a.anchor::before,
body.dark-mode h3 a.anchor::before {
  color: var(--text-color);
  opacity: 0.6;
}
body.dark-mode #header-search-label:focus-within {
  box-shadow: 0 0 0 10000px rgba(235, 235, 255, 0.1);
}
body.dark-mode nav input[type="search"] {
  border: 0;
}
body.dark-mode .search-result-count {
  right: 0.6em;
}
body.dark-mode #header-search:focus,
body.dark-mode .autocomplete-suggestions {
  box-shadow: 0px 4px 12px rgba(10, 10, 10, 0.4);
}
body.dark-mode footer {
  border: none;
}

/* dark mode toggle */

label.toggle,
input.toggle {
  font-size: 22px;
}

input.toggle[type="checkbox"] {
  height: 0;
  visibility: hidden;
  width: 0;
}

label.toggle {
  background: var(--secondary-fire-color);
  border-radius: 1em;
  cursor: pointer;
  display: block;
  height: 0.5em;
  position: relative;
  transition: 0.2s;
  width: 1.4em;
  margin-left: 0.5em;
}

label.toggle span {
  background: var(--primary-base-background-color);
  border-radius: 0.5em;
  border: solid 1px var(--secondary-fire-color);
  box-sizing: border-box;
  color: var(--secondary-fire-color);
  display: block;
  height: 1em;
  position: absolute;
  text-align: center;
  left: -0.1em;
  top: -0.25em;
  transition: 0.2s;
  width: 1em;
}
label.toggle span span {
  border: none;
  -webkit-font-smoothing: antialiased;
  display: inline;
  font-family: "Font Awesome 5 Free";
  font-size: 0.5em;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  left: 50%;
  position: absolute;
  text-rendering: auto;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

input.toggle:checked + label.toggle {
  background-color: var(--secondary-saphire-color);
}
input.toggle:checked + label.toggle span {
  border: solid 1px var(--secondary-saphire-color);
  color: var(--secondary-saphire-color);
  left: 0.7rem;
}
input.toggle:checked + label.toggle span span {
  left: 0.615rem;
  border: none;
}

label.toggle span:active {
  width: 1.1em;
}
input.toggle:checked + label.toggle span:active {
  left: 0.4em;
}

/*PRISM OVERRIDES*/

body :not(pre) > code[class*="language-"],
body pre[class*="language-"] {
  background: var(--contents-box-background);
}

body div.code-toolbar > .toolbar span {
  background: var(--contents-box-background);
}

body.dark-mode :not(pre) > code[class*="language-"],
body.dark-mode pre[class*="language-"] {
  background: var(--contents-box-background);
}

body .box {
  padding: var(--gutter);
  background: var(--contents-box-background);
}
