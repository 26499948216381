@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-Bold.otf") format("OpenType");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-BoldItalic.otf") format("OpenType");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-ExtraLight.otf") format("OpenType");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-ExtraLightItalic.otf") format("OpenType");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-Italic.otf") format("OpenType");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-Light.otf") format("OpenType");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-LightItalic.otf") format("OpenType");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-Medium.otf") format("OpenType");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-MediumItalic.otf") format("OpenType");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-Regular.otf") format("OpenType");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-SemiBold.otf") format("OpenType");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-SemiBoldItalic.otf") format("OpenType");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-Text.otf") format("OpenType");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-TextItalic.otf") format("OpenType");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-Thin.otf") format("OpenType");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Mono";
  src: url("./IBMPlexMono-ThinItalic.otf") format("OpenType");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Arial";
  src: url("./Arial.ttf") format("TrueType");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Arial";
  src: url("./Arial-Black.ttf") format("TrueType");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Arial";
  src: url("./Arial-Bold.ttf") format("TrueType");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Arial";
  src: url("./Arial-Bold-Italic.ttf") format("TrueType");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Arial";
  src: url("./Arial-Italic.ttf") format("TrueType");
  font-weight: 400;
  font-style: italic;
}
